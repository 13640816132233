html {
  -webkit-text-size-adjust: 100%; /* Prevent font scaling in landscape while allowing user zoom */
}
body,
html,
#root,
.ant-app {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  overflow: hidden;
}
#root {
  padding: env(safe-area-inset-top, 20px) env(safe-area-inset-right, 20px) 0px
    env(safe-area-inset-left, 20px);
  box-sizing: border-box;
}
/* env(safe-area-inset-bottom, 20px) #1DA57A */
body {
  background-color: #fff;
}

body {
  font-family: -apple-system, Segoe UI, Roboto, Ubuntu, Cantarell, Noto Sans,
    sans-serif, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  -webkit-touch-callout: none !important;
}

.logo {
  height: 32px;
  margin: 16px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  color: #000;
}
.logo__title {
  padding-left: 8px;
  font-size: 18px;
  font-weight: bold;
  line-height: 32px;
}
.site-layout-sub-header-background {
  background: #fff !important;
}
.card,
.formCard {
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 8px;
  border-radius: 8px;
}
.formCard {
  padding: 16px;
  max-width: 640px;
  margin-left: auto;
  margin-right: auto;
}
.popup-content {
  background: #fff;
  border-radius: 8px;
  padding: 16px;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
}
.popup-arrow {
  color: #fff;
}
.popup-content__title {
  padding: 0 0 8px;
}
.pageHeader {
  display: flex;
  align-items: center;
}
.pageHeader__spacer {
  width: 8px;
  height: 8px;
  flex-shrink: 0;
}
.pageHeader__icon {
  width: 40px;
  height: 40px;
}
.pageHeader__icon img {
  vertical-align: top;
}
.pageHeader__mobile-spacer {
  height: 8px;
  flex-grow: 1;
  display: none;
}
.pageHeader__name {
  flex-grow: 1;
}
.pageHeader__sidebar {
  flex-shrink: 0;
}

.profileMedia {
  width: 128px;
  height: 128px;
  border-radius: 8px;
  overflow: hidden;
  background: #aaa;
  background: linear-gradient(
    0deg,
    rgba(170, 170, 170, 1) 0%,
    rgba(136, 136, 136, 1) 100%
  );
  position: relative;
}
.profileMediaUpload {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  top: 50%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  opacity: 0;
  color: #fff;
}
.profileMedia:hover .profileMediaUpload,
.profileMedia:hover .profileMediaDelete {
  opacity: 1 !important;
}

.ant-picker .ant-picker-input span.ant-picker-suffix,
.ant-picker.ant-picker-range span.ant-picker-suffix {
  margin-left: 0;
  margin-right: 10px;
  order: -1;
}
.ant-select-selection-search-input {
  font-size: 16px;
}

/* useAnimatedToggle styles */
.fs-hide-root {
  display: grid;
  grid-template-rows: 1fr;
  transition-property: grid-template-rows;
}

.fs-hide-root.is-hidden {
  grid-template-rows: 0fr;
}

.fs-hide-root__inner {
  overflow: hidden;
}

.fs-hide-item {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

.fs-calendar-tab {
  display: block;
  width: 40px;
  text-align: center;
  & span:first-child {
    font-size: 12px;
    display: block;
  }
  & span:last-child {
    font-size: 18px;
    font-weight: 500;
    line-height: 1;
  }
}

blockquote {
  border-left: rgba(0, 0, 0, 0.1) solid 0.25rem;
  margin-left: 0;
  padding-left: 1.5rem;
}

.tiptap {
  outline: none;
}
p.is-editor-empty:first-child::before {
  opacity: 0.5;
  content: attr(data-placeholder);
  float: left;
  height: 0;
  pointer-events: none;
}
.notice {
  display: inline-block;
  border-radius: 6px;
  padding: 8px 14px;
  border: 1px solid rgba(0, 0, 0, 0.1);
}
.screenshot {
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  vertical-align: top;
  width: 100%;
}
.fs-dark {
  blockquote {
    border-left: rgba(255, 255, 255, 0.1) solid 0.25rem;
  }
  .notice {
    border: 1px solid rgba(255, 255, 255, 0.2);
  }
  .screenshot {
    border: 1px solid rgba(255, 255, 255, 0.15);
  }
}
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.animateSpin {
  animation: spin 2s infinite linear;
}

.ant-message-notice {
  margin-top: env(safe-area-inset-top, 20px);
}
@keyframes blinki {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 0;
  }
}
.blinking {
  animation: blinki 1.275s infinite linear;
}

@media screen and (max-width: 700px) {
  .pageHeader {
    flex-wrap: wrap;
  }
  .pageHeader__spacer {
    display: none;
  }
  .pageHeader__icon {
    order: 0;
  }
  .pageHeader__name {
    order: 2;
    flex-basis: 100%;
  }
  .pageHeader__sidebar {
    order: 1;
  }
  .pageHeader__mobile-spacer {
    display: block;
  }
}
body.fs-dark {
  background-color: #111;
  color: rgba(255, 255, 255, 0.85);
  & .popup-content {
    background: #1f1f1f;
  }
  & .popup-arrow {
    color: #1f1f1f;
  }
  & .card,
  & .formCard {
    border: 1px solid rgba(255, 255, 255, 0.1);
  }
  & h1,
  & h2,
  & h3,
  & h4,
  & h5,
  & h6,
  & .logo {
    color: rgba(255, 255, 255, 0.85);
  }
}
