.app {
  background: transparent;
}
.questBtn,
.emailBtn {
  background: rgba(0, 0, 0, 0.1);
  border: none;
  border-width: 0;
  margin: 0;
  padding: 0;
  outline: none;
  padding: 6px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  font-weight: bold;
  color: #333;
  -webkit-appearance: none;
  appearance: none;
  width: 100%;
}
.emailBtn {
  background: rgba(82, 196, 26, 0.19) !important;
  color: rgb(43, 101, 14) !important;
}
:global(.fs-dark) {
  .questBtn {
    color: #eee;
    background: rgba(133, 133, 133, 0.27);
  }
  .emailBtn {
    background: rgba(82, 196, 26, 0.23) !important;
    color: rgb(82, 196, 26) !important;
  }
}
:global(.fs-nav-sidebar) {
  .questBtn,
  .emailBtn {
    border-radius: 4px;
    font-size: 16px;
    text-align: left;
  }
}
