.bubbleMenu {
  padding: 4px;
  border-radius: 10px;
  background: #fff;
  box-shadow: 0px 12px 33px 0px rgba(0, 0, 0, 0.06),
    0px 3.618px 9.949px 0px rgba(0, 0, 0, 0.04);
  display: flex;
  gap: 4px;
  word-wrap: break-word;
  word-break: break-all;
}
.bubbleButton {
  border: none;
  margin: 0;
  padding: 0 6px;
  background: rgb(22, 119, 255);
  height: 28px;
  line-height: 28px;
  font-size: 16px;
  border-radius: 6px;
  flex-shrink: 0;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: white;
  &:hover {
    background: rgba(22, 119, 255, 0.9);
  }
}
.bubbleButton--icon {
  padding: 0;
  width: 28px;
  background: transparent;
  color: currentColor;
  &:hover {
    background: rgba(0, 0, 0, 0.05);
  }
}
.bubbleButton--active {
  background: rgba(0, 0, 0, 0.1);
}
.bubbleInput {
  border: none;
  margin: 0;
  outline: 1px solid rgba(0, 0, 0, 0.3);
  border-radius: 6px;
  padding: 0 6px;
  min-height: 28px;
  font-size: 16px;
  line-height: 28px;
  display: block;
  width: 100%;
  &:focus {
    outline: 1px solid rgba(0, 0, 0, 1);
    outline-offset: 0px;
  }
}
.bubbleEditor {
  width: 300px;
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.editorRoot {
  position: relative;
}
.editToggle {
  visibility: hidden;
  pointer-events: none;
  position: sticky;
  top: 10px;
  z-index: 1;
}
.editorRoot:hover .editToggle {
  visibility: visible;
  pointer-events: initial;
}

:global(.fs-dark) {
  .bubbleMenu {
    background: #1f1f1f;
    box-shadow: none;
    outline: 1px solid rgba(255, 255, 255, 0.15);
  }
  .bubbleButton--icon:hover,
  .bubbleButton--active {
    background: rgba(255, 255, 255, 0.15);
  }
}
