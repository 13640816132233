.Contenteditable {
  border-radius: 4px;
  cursor: text;
}
.Contenteditable:focus-visible {
  padding-right: 1px;
  outline: 1px solid currentColor;
  outline-offset: 2px;
}
.Contenteditable:before {
  content: var(--placeholder-text-var);
}