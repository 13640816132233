.row {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 8px;
}
.btn {
  display: block;
  flex-grow: 1;
  box-sizing: border-box;
  height: 48px;
  padding: 8px 14px;
  border: 0px solid transparent;
  font-size: 16px;
  font-weight: 500;
  line-height: 16px;
  cursor: pointer;
  border-radius: 8px;
}
.btn--positive {
  color: #3ba026;
  border-color: #3ba026;
  background-color: rgba(59, 160, 38, 0.1);
}
.btn--negaitive {
  color: #ce4040;
  border-color: #ce4040;
  background-color: rgba(206, 64, 64, 0.1);
}
.btn--active {
  border-width: 1px;
}
.btn--inactive {
  opacity: 0.5;
}
.textfield {
  margin: 1px;
  padding: 4px 8px;
  border-radius: 6px;
  outline: rgba(0, 0, 0, 0.1) solid 1px;
}
:global(.fs-dark) {
  .textfield {
    outline: rgba(255, 255, 255, 0.2) solid 1px;
  }
}
